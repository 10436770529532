import React from 'react';
import websiteLogo from '../assets/images/OmniStonedObi_logo_328.png';

const MobilePageTitle = (props:{title: string}) => {
  return (
    <div>
      <div className='p-4'>
        <img
          src={websiteLogo}
          alt=""
          className="block mx-auto w-2/3 tablet:hidden rounded-full bg-gradient-to-r from-pink to-purple shadow-md shadow-black"
          onClick={() => {
            window.location.href = '/';
          }}
        />
      </div>
      <p className="font-oswald block tablet:hidden text-lightBrown font-bold text-lg tablet:text-xxl text-center py-1 tracking-wide text-blue">
        {props.title}
      </p>
      <div>
        <div className="border-b-2 border-pink w-10/12 block tablet:border-0 mx-auto"></div>
      </div>
    </div>
  );
};

export default MobilePageTitle;
