import React, { useEffect, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import axios from 'axios';

type StreamingTime = {
  day: string;
  start: string;
  end: string;
  timezone?: string;
};

const streamingTimes: StreamingTime[] = [
  { day: 'Monday', start: '-', end: '-' },
  { day: 'Tuesday', start: '-', end: '-' },
  { day: 'Wednesday', start: '13:00', end: '16:00', timezone: 'Europe/London' },
  { day: 'Thursday', start: '-', end: '-' },
  { day: 'Friday', start: '-', end: '-' },
  { day: 'Saturday', start: 'Tentative', end: 'Tentative' },
  { day: 'Sunday', start: '-', end: '-' },
];

const StreamingSchedule = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [streamerIsLive, setStreamerIsLive] = useState(false);

  const twitchChannel = 'omnistonedobi';

  let yourWebsiteDomain;

  if (process.env.NODE_ENV === 'production') {
    yourWebsiteDomain = process.env.REACT_APP_API_URL;
  } else {
    yourWebsiteDomain = 'localhost';
  }

  const twitchEmbedUrl = `https://player.twitch.tv/?channel=${twitchChannel}&parent=${yourWebsiteDomain}`;

  const convertTimeToLocal = (
    time: string,
    timezone: string | undefined = 'Europe/London',
  ) => {
    if (!time || time.toLowerCase() === 'tentative' || time === '-')
      return time;
    const date = new Date(`2023-01-01T${time}:00`);
    const zonedDate = utcToZonedTime(date, timezone);
    return format(zonedDate, 'h:mm a', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const renderTimeString = (time: StreamingTime) => {
    if (time.start === 'Tentative' && time.end === 'Tentative') {
      return 'Tentative';
    } else if (time.start === '-' && time.end === '-') {
      return 'Inactive';
    }
    return `${convertTimeToLocal(
      time.start,
      time.timezone,
    )} - ${convertTimeToLocal(time.end, time.timezone)}`;
  };

  useEffect(() => {
    const fetchStreamerStatus = async () => {
      try {
        const response = await axios.get('/.netlify/functions/streamerIsLive');
        console.log(response);
        if (response.headers['content-type']?.includes('application/json')) {
          console.log(response.data);
          if (response.status === 200) {
            setStreamerIsLive(response.data.streaming);
          } else {
            console.error(
              'Error fetching streaming status:',
              response.data.error,
            );
          }
        } else {
          console.error('Non-JSON response received:', response.data);
          setStreamerIsLive(response.data.streaming);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        } else {
          console.error('An unexpected error occurred:', error);
        }
      }
    };
    fetchStreamerStatus();
  }, []);

  return (
    <div
      className={`${
        !streamerIsLive
          ? 'bg-gradient-to-r from-slate-500 via-slate-300 to-slate-500 rounded-xl shadow-lg shadow-blue'
          : ''
      } w-11/12 tablet:w-8/12 mx-auto mt-5 mb-12 font-poppins pb-8`}
    >
      {!streamerIsLive ? (
        <>
          <div className="py-5">
            <p className="mx-auto w-10/12 tablet:w-2/3 text-center text-md tablet:text-lg font-bold border-b-2 border-purple text-black">
              Streaming Schedule
            </p>
            <p className="mx-auto w-10/12 tablet:w-2/3 text-center text-xxs tablet:text-xs text-purple font-libre">
              TimeZone: {userTimeZone}
            </p>
          </div>
          <div className="flex flex-col w-11/12 tablet:w-8/12 mx-auto rounded-md">
            {streamingTimes.map((time, i) => {
              const timeString = renderTimeString(time);
              const isActive = timeString !== 'Inactive';
              const bgColorClass = isActive
                ? 'bg-gradient-to-r from-pink to-blue shadow-purple shadow-md'
                : 'bg-transparent';

              return (
                <div
                  key={i}
                  className={`flex my-1 py-2 rounded-md text-purple font-extraBold text-xs tablet:text-sm ${bgColorClass}`}
                >
                  <div className="flex-1 text-left pl-5 tablet:pl-0 tablet:text-center">
                    {time.day}:
                  </div>
                  <div className="flex-1 text-left -ml-4 tablet:pl-0 tablet:text-center">
                    {timeString}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <iframe
          src={twitchEmbedUrl}
          height="480" // Adjust height as needed
          width="100%"
          frameBorder="0"
          scrolling="no"
          allowFullScreen={true}
          title="twitchLiveStream"
          className="pt-8"
        ></iframe>
      )}
    </div>
  );
};

export default StreamingSchedule;
