import React from 'react';
import youtubeLogo from '../assets/icons/socialMedia/youtube-svgrepo-com.svg';
import twitchLogo from '../assets/icons/socialMedia/twitch-svgrepo-com.svg';
import twitterLogo from '../assets/icons/socialMedia/twitter-svgrepo-com.svg';
// import instagramLogo from '../assets/icons/socialMedia/instagram-svgrepo-com.svg';
// import discordLogo from '../assets/icons/socialMedia/discord-svgrepo-com.svg';

const socialMediaLinks = [
  {
    label: 'Twitch',
    link: 'https://www.twitch.tv/omnistonedobi',
    logo: twitchLogo,
  },
  { label: 'YouTube', link: 'https://www.youtube.com/channel/UCKCMD3BzFB-JODzcZGfEJ_w', logo: youtubeLogo },
  {
    label: 'Twitter',
    link: 'https://twitter.com/OmnistonedObi',
    logo: twitterLogo,
  },
  // { label: 'Discord', link: 'https://discord.com/', logo: discordLogo },
  // { label: 'Instagram', link: 'https://discord.com/', logo: discordLogo },
];

const SocialMediaLinks = () => {
  return (
    <div className="flex space-x-4 justify-center items-center my-4">
      {socialMediaLinks.map((socialMedia, index) => (
        <a
          key={index}
          href={socialMedia.link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={socialMedia.label}
          className="flex items-center tablet:space-x-2 hover:text-gray-800 transition-colors rounded-full hover:bg-gradient-to-r from-purple to-pink p-4 duration-500"
        >
          <img
            src={socialMedia.logo}
            alt={socialMedia.label}
            className="h-16"
          />
          <span className="hidden md:inline">{socialMedia.label}</span>
        </a>
      ))}
    </div>
  );
};

export default SocialMediaLinks;
