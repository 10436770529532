import { webPages } from './assets/webPages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Error404Page from './pages/404';
import Home from './pages/Home';
import './index.css'

const App = () => {
  return (
    <BrowserRouter>
      <div className='bg-black min-h-screen font-poppins'>
        <Routes>
          <Route path='/' element={<Home />} />
          {webPages.map(({ route, reactComponent }, i) => {
            return <Route path={route} element={React.createElement(reactComponent)} key={i} />;
          })}
          <Route path='/*' element={<Error404Page />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
