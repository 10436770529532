import React from 'react';
import binaryLogo from '../assets/images/BINARY_ARTISAN-removebg.png';

const goToWebsite = () => {
  window.open('https://binaryartisan.com', '_blank');
};

const PoweredByBA = () => {
  return (
    <>
      <div>
        <div className="border-b-2 tablet:border-0 border-pink w-10/12 block mx-auto"></div>
      </div>

      <div className="flex items-center space-x-4 mx-auto w-11/12 tablet:w-10/12 my-8 rounded-lg p-3 text-txtColor3">
        <div className="tablet:w-8/12 none tablet:inline-block" />
        <div className="w-full tablet:w-4/12 flex justify-between items-center text-white">
          <p className="inline-block w-2/3 text-xs tablet:text-xxs laptop:text-sm">
            This site is powered by{' '}
            <span
              className="font-bold text-sm tablet:text-xs laptop:text-md"
              onClick={goToWebsite}
            >
              Binary Artisan
            </span>
          </p>
          <img
            src={binaryLogo}
            alt="binary artisan logo"
            className="w-4/12 inline-block cursor-pointer shadow-lg rounded-full bg-transparent"
            onClick={goToWebsite}
          />
        </div>
      </div>
    </>
  );
};

export default PoweredByBA;
