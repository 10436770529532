import React from 'react';
import mediumLogo from '../assets/images/OmniStonedObi_logo_328.png';

const NavBar = (navButton: {label:string, location:string}) => {
  return (
    <div className="hidden tablet:flex font-poppins shadow-md shadow-blue bg-gradient-to-r from-pink to-purple">
      <div className="flex items-center justify-center p-2 w-3/12">
        <img
          src={mediumLogo}
          alt=""
          className="w-28 rounded-full border-purple border bg-black shadow-md shadow-black"
          onClick={() => {
            window.location.href = '/';
          }}
        />
      </div>
      <div className="flex-rows justify-center items-center p-2 py-5 w-9/12 text-blue">
        <p className="block mx-auto text-sm">Welcome to</p>
        <p className="block mx-auto text-lg">
          <span className="font-bold">OmniStonedObi.Social</span>
        </p>
      </div>
      <div
        className="relative right-12 top-10 w-32 h-8 p-1 rounded-full text-center text-purple bg-blue hover:bg-black hover:text-pink duration-500"
        onClick={() => {
          window.location.href = navButton.location;
        }}
      >
        {navButton.label}
      </div>
    </div>
  );
};

export default NavBar;
