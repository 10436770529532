import React from 'react';

const paragraphs = [
  `Hi there, my names Oli / Obi, I am a psychology student at Newcastle, ADHD and Autism researcher with Newcastle University, and overall, 420 enthusiasts. I am non-binary and use the pronouns they/she/he. Welcome to my website!`,
  `You can find all my links to my various social media accounts where I mainly just post about my stream but sometimes you will find me in a shitposty mood! You will also see my schedule or a link to my twitch if I’m live! I stream mainly poker and want to foster a friendly, accepting environment. That means no being rude, using slurs or being purposefully mean to anyone in the community. I take that very seriously and I am a sensitive little baby.`,
  `Please feel free to swing by and come talk to me whenever I am online or contact me through the website if you need anything!`,
];

const AboutBio = () => {
  return (
    <div className="w-11/12 tablet:w-8/12 mx-auto my-12 bg-gradient-to-r from-slate-500 via-slate-300 to-slate-500 rounded-xl shadow-lg shadow-blue font-poppins pb-8">
      <div className="py-5">
        <p className="mx-auto w-10/12 tablet:w-2/3 text-center text-md tablet:text-lg font-bold border-b-2 border-purple text-black">
          About Me
        </p>
      </div>
      <div className="flex flex-col w-11/12 tablet:w-9/12 mx-auto">
        {paragraphs.map((para, i) => {
          return (
            <p className="text-xs tablet:text-sm text-purple my-3 text-center" key={i}>
              {para}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default AboutBio;
