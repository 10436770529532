import React from "react";
import NavBar from "../components/NavBar";
import PoweredByBA from "../components/PoweredByBinaryArtisan";
import SocialMediaLinks from "../components/SocialMediaLinks";
import MobilePageTitle from "../components/MobilePageTitle";
import MobileNavbar from "../components/MobileNavBar";

const Error404Page = () => {
  return (
    <div>
      <MobileNavbar />
      <NavBar label="Home" location="/" />
      <MobilePageTitle title="OmniStonedObi" />
      <div>
        <p>Error <span>404</span>: <span>Page Not Found</span></p>
      </div>
      <SocialMediaLinks />
      <PoweredByBA />
    </div>
  );
};

export default Error404Page;
