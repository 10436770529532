import React from 'react';
import NavBar from '../components/NavBar';
import PoweredByBA from '../components/PoweredByBinaryArtisan';
import SocialMediaLinks from '../components/SocialMediaLinks';
import AboutBio from '../components/AboutBio';
import StreamingSchedule from '../components/StreamingSchedule';
import MobileNavbar from '../components/MobileNavBar';
import MobilePageTitle from '../components/MobilePageTitle';

const Home = () => {
  return (
    <div className="font-poppins">
      <MobileNavbar />
      <NavBar label="Contact" location="/contact" />
      <MobilePageTitle title="OmniStonedObi" />
      <div>
        <div>
          <StreamingSchedule />
        </div>
        <div>
          <AboutBio />
        </div>
        <div>
          <SocialMediaLinks />
        </div>
      </div>
      <PoweredByBA />
    </div>
  );
};

export default Home;
