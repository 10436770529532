import React from "react";
import ContactForm from "../components/ContactForm";
import NavBar from "../components/NavBar";
import PoweredByBA from "../components/PoweredByBinaryArtisan";
import MobileNavbar from "../components/MobileNavBar";
import MobilePageTitle from "../components/MobilePageTitle";
import SocialMediaLinks from "../components/SocialMediaLinks";

const Contact = () => {
  return (
    <div>
      <MobileNavbar />
      <NavBar label="Home" location="/" />
      <MobilePageTitle title="OmniStonedObi" />
      <ContactForm />
      <SocialMediaLinks />
      <PoweredByBA />
    </div>
  );
};

export default Contact;
